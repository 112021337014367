// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }

    // Javascript extensions for more readable code
    if (!Array.prototype.first) {
        Array.prototype.first = function(){
            return this[0];
        };
    }
    if (!Array.prototype.last) {
        Array.prototype.last = function(){
            return this[this.length - 1];
        };
    }
}());

// Place any jQuery/helper plugins in here.


(function($) {
    $.fn.slider = function(options) {
        var settings = $.extend({
            duration: 8000,
            nextSlide: ".next-slide",
            prevSlide: ".previous-slide",
        }, options);

        var buttonsInactive = false;

        return this.each(function() {
            var slides = [];
            if ($(this).find("li").length > 1) {
                $(this).find("li").each(function (index) {
                    var slide = new Slide(settings.duration, $(this));
                    if (slides.length > 0) {
                        slide.setPreviousElement(slides.last());
                        slides.last().setNextElement(slide);
                    }
                    slides.push(slide);
                });
                slides.first().setPreviousElement(slides.last());
                slides.last().setNextElement(slides.first());
                // remember: first() und last() methods on arrays are not standard js; we have added them via the prototype property above!

                slides.first().autoPlay();
            }
            $(settings.prevSlide).on("click", function (event) {
                if (buttonsInactive) {
                    return;
                }
                event.preventDefault();
                slides.some(function (slide) {
                    return slide.previous();
                })
            });
            $(settings.nextSlide).on("click", function (event) {
                if (buttonsInactive) {
                    return;
                }
                event.preventDefault();
                slides.some(function (slide) {
                    return slide.next();
                })
            });
        });

        function Slide (duration, element) {
            return {
                duration: duration,
                currentElement: element,
                previousElement: null,
                nextElement: null,
                timer: null,
                visible: false,

                next: function () {
                    buttonsInactive = true;
                    if (!this.visible) {
                        return false;
                    }
                    this.closeSlide();
                    this.nextElement.autoPlay();

                    return true;
                },
                previous: function() {
                    buttonsInactive = true;
                    if (!this.visible) {
                        return false;
                    }
                    this.closeSlide();
                    this.previousElement.autoPlay();

                    return true;
                },
                autoPlay: function () {
                    this.currentElement.show();
                    this.currentElement.animate({
                        opacity: 1
                    }, 500);
                    this.visible = true;

                    that = this;
                    this.timer = setTimeout(function () {
                        that.next();
                    }, this.duration);
                },
                setNextElement: function (nextElement) {
                    this.nextElement = nextElement;
                },
                setPreviousElement: function (previousElement) {
                    this.previousElement = previousElement;
                },
                closeSlide: function () {
                    clearTimeout(this.timer);
                    var that = this;
                    this.currentElement.animate({
                        opacity: 0
                    }, 500, 'swing', function() {
                        that.currentElement.hide();
                        buttonsInactive = false;
                    });
                    this.visible = false;
                }
            };
        }
    };
}(jQuery));