/**
 * rome - Customizable date (and time) picker. Opt-in UI, no jQuery!
 * @version v2.1.22
 * @link https://github.com/bevacqua/rome
 * @license MIT
 */
(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.rome = f()}})(function(){var define,module,exports;return (function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
    'use strict';

    var crossvent = require('crossvent');
    var throttle = require('./throttle');
    var tailormade = require('./tailormade');

    function bullseye (el, target, options) {
        var o = options;
        var domTarget = target && target.tagName;

        if (!domTarget && arguments.length === 2) {
            o = target;
        }
        if (!domTarget) {
            target = el;
        }
        if (!o) { o = {}; }

        var destroyed = false;
        var throttledWrite = throttle(write, 30);
        var tailorOptions = { update: o.autoupdateToCaret !== false && update };
        var tailor = o.caret && tailormade(target, tailorOptions);

        write();

        if (o.tracking !== false) {
            crossvent.add(window, 'resize', throttledWrite);
        }

        return {
            read: readNull,
            refresh: write,
            destroy: destroy,
            sleep: sleep
        };

        function sleep () {
            tailorOptions.sleeping = true;
        }

        function readNull () { return read(); }

        function read (readings) {
            var bounds = target.getBoundingClientRect();
            var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
            if (tailor) {
                readings = tailor.read();
                return {
                    x: (readings.absolute ? 0 : bounds.left) + readings.x,
                    y: (readings.absolute ? 0 : bounds.top) + scrollTop + readings.y + 20
                };
            }
            return {
                x: bounds.left,
                y: bounds.top + scrollTop
            };
        }

        function update (readings) {
            write(readings);
        }

        function write (readings) {
            if (destroyed) {
                throw new Error('Bullseye can\'t refresh after being destroyed. Create another instance instead.');
            }
            if (tailor && !readings) {
                tailorOptions.sleeping = false;
                tailor.refresh(); return;
            }
            var p = read(readings);
            if (!tailor && target !== el) {
                p.y += target.offsetHeight;
            }
            el.style.left = p.x + 'px';
            el.style.top = p.y + 'px';
        }

        function destroy () {
            if (tailor) { tailor.destroy(); }
            crossvent.remove(window, 'resize', throttledWrite);
            destroyed = true;
        }
    }

    module.exports = bullseye;

},{"./tailormade":11,"./throttle":12,"crossvent":18}],2:[function(require,module,exports){
    (function (global){
        'use strict';

        var getSelection;
        var doc = global.document;
        var getSelectionRaw = require('./getSelectionRaw');
        var getSelectionNullOp = require('./getSelectionNullOp');
        var getSelectionSynthetic = require('./getSelectionSynthetic');
        var isHost = require('./isHost');
        if (isHost.method(global, 'getSelection')) {
            getSelection = getSelectionRaw;
        } else if (typeof doc.selection === 'object' && doc.selection) {
            getSelection = getSelectionSynthetic;
        } else {
            getSelection = getSelectionNullOp;
        }

        module.exports = getSelection;

    }).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{"./getSelectionNullOp":3,"./getSelectionRaw":4,"./getSelectionSynthetic":5,"./isHost":6}],3:[function(require,module,exports){
    'use strict';

    function noop () {}

    function getSelectionNullOp () {
        return {
            removeAllRanges: noop,
            addRange: noop
        };
    }

    module.exports = getSelectionNullOp;

},{}],4:[function(require,module,exports){
    (function (global){
        'use strict';

        function getSelectionRaw () {
            return global.getSelection();
        }

        module.exports = getSelectionRaw;

    }).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{}],5:[function(require,module,exports){
    (function (global){
        'use strict';

        var rangeToTextRange = require('./rangeToTextRange');
        var doc = global.document;
        var body = doc.body;
        var GetSelectionProto = GetSelection.prototype;

        function GetSelection (selection) {
            var self = this;
            var range = selection.createRange();

            this._selection = selection;
            this._ranges = [];

            if (selection.type === 'Control') {
                updateControlSelection(self);
            } else if (isTextRange(range)) {
                updateFromTextRange(self, range);
            } else {
                updateEmptySelection(self);
            }
        }

        GetSelectionProto.removeAllRanges = function () {
            var textRange;
            try {
                this._selection.empty();
                if (this._selection.type !== 'None') {
                    textRange = body.createTextRange();
                    textRange.select();
                    this._selection.empty();
                }
            } catch (e) {
            }
            updateEmptySelection(this);
        };

        GetSelectionProto.addRange = function (range) {
            if (this._selection.type === 'Control') {
                addRangeToControlSelection(this, range);
            } else {
                rangeToTextRange(range).select();
                this._ranges[0] = range;
                this.rangeCount = 1;
                this.isCollapsed = this._ranges[0].collapsed;
                updateAnchorAndFocusFromRange(this, range, false);
            }
        };

        GetSelectionProto.setRanges = function (ranges) {
            this.removeAllRanges();
            var rangeCount = ranges.length;
            if (rangeCount > 1) {
                createControlSelection(this, ranges);
            } else if (rangeCount) {
                this.addRange(ranges[0]);
            }
        };

        GetSelectionProto.getRangeAt = function (index) {
            if (index < 0 || index >= this.rangeCount) {
                throw new Error('getRangeAt(): index out of bounds');
            } else {
                return this._ranges[index].cloneRange();
            }
        };

        GetSelectionProto.removeRange = function (range) {
            if (this._selection.type !== 'Control') {
                removeRangeManually(this, range);
                return;
            }
            var controlRange = this._selection.createRange();
            var rangeElement = getSingleElementFromRange(range);
            var newControlRange = body.createControlRange();
            var el;
            var removed = false;
            for (var i = 0, len = controlRange.length; i < len; ++i) {
                el = controlRange.item(i);
                if (el !== rangeElement || removed) {
                    newControlRange.add(controlRange.item(i));
                } else {
                    removed = true;
                }
            }
            newControlRange.select();
            updateControlSelection(this);
        };

        GetSelectionProto.eachRange = function (fn, returnValue) {
            var i = 0;
            var len = this._ranges.length;
            for (i = 0; i < len; ++i) {
                if (fn(this.getRangeAt(i))) {
                    return returnValue;
                }
            }
        };

        GetSelectionProto.getAllRanges = function () {
            var ranges = [];
            this.eachRange(function (range) {
                ranges.push(range);
            });
            return ranges;
        };

        GetSelectionProto.setSingleRange = function (range) {
            this.removeAllRanges();
            this.addRange(range);
        };

        function createControlSelection (sel, ranges) {
            var controlRange = body.createControlRange();
            for (var i = 0, el, len = ranges.length; i < len; ++i) {
                el = getSingleElementFromRange(ranges[i]);
                try {
                    controlRange.add(el);
                } catch (e) {
                    throw new Error('setRanges(): Element could not be added to control selection');
                }
            }
            controlRange.select();
            updateControlSelection(sel);
        }

        function removeRangeManually (sel, range) {
            var ranges = sel.getAllRanges();
            sel.removeAllRanges();
            for (var i = 0, len = ranges.length; i < len; ++i) {
                if (!isSameRange(range, ranges[i])) {
                    sel.addRange(ranges[i]);
                }
            }
            if (!sel.rangeCount) {
                updateEmptySelection(sel);
            }
        }

        function updateAnchorAndFocusFromRange (sel, range) {
            var anchorPrefix = 'start';
            var focusPrefix = 'end';
            sel.anchorNode = range[anchorPrefix + 'Container'];
            sel.anchorOffset = range[anchorPrefix + 'Offset'];
            sel.focusNode = range[focusPrefix + 'Container'];
            sel.focusOffset = range[focusPrefix + 'Offset'];
        }

        function updateEmptySelection (sel) {
            sel.anchorNode = sel.focusNode = null;
            sel.anchorOffset = sel.focusOffset = 0;
            sel.rangeCount = 0;
            sel.isCollapsed = true;
            sel._ranges.length = 0;
        }

        function rangeContainsSingleElement (rangeNodes) {
            if (!rangeNodes.length || rangeNodes[0].nodeType !== 1) {
                return false;
            }
            for (var i = 1, len = rangeNodes.length; i < len; ++i) {
                if (!isAncestorOf(rangeNodes[0], rangeNodes[i])) {
                    return false;
                }
            }
            return true;
        }

        function getSingleElementFromRange (range) {
            var nodes = range.getNodes();
            if (!rangeContainsSingleElement(nodes)) {
                throw new Error('getSingleElementFromRange(): range did not consist of a single element');
            }
            return nodes[0];
        }

        function isTextRange (range) {
            return range && range.text !== void 0;
        }

        function updateFromTextRange (sel, range) {
            sel._ranges = [range];
            updateAnchorAndFocusFromRange(sel, range, false);
            sel.rangeCount = 1;
            sel.isCollapsed = range.collapsed;
        }

        function updateControlSelection (sel) {
            sel._ranges.length = 0;
            if (sel._selection.type === 'None') {
                updateEmptySelection(sel);
            } else {
                var controlRange = sel._selection.createRange();
                if (isTextRange(controlRange)) {
                    updateFromTextRange(sel, controlRange);
                } else {
                    sel.rangeCount = controlRange.length;
                    var range;
                    for (var i = 0; i < sel.rangeCount; ++i) {
                        range = doc.createRange();
                        range.selectNode(controlRange.item(i));
                        sel._ranges.push(range);
                    }
                    sel.isCollapsed = sel.rangeCount === 1 && sel._ranges[0].collapsed;
                    updateAnchorAndFocusFromRange(sel, sel._ranges[sel.rangeCount - 1], false);
                }
            }
        }

        function addRangeToControlSelection (sel, range) {
            var controlRange = sel._selection.createRange();
            var rangeElement = getSingleElementFromRange(range);
            var newControlRange = body.createControlRange();
            for (var i = 0, len = controlRange.length; i < len; ++i) {
                newControlRange.add(controlRange.item(i));
            }
            try {
                newControlRange.add(rangeElement);
            } catch (e) {
                throw new Error('addRange(): Element could not be added to control selection');
            }
            newControlRange.select();
            updateControlSelection(sel);
        }

        function isSameRange (left, right) {
            return (
                left.startContainer === right.startContainer &&
                left.startOffset === right.startOffset &&
                left.endContainer === right.endContainer &&
                left.endOffset === right.endOffset
            );
        }

        function isAncestorOf (ancestor, descendant) {
            var node = descendant;
            while (node.parentNode) {
                if (node.parentNode === ancestor) {
                    return true;
                }
                node = node.parentNode;
            }
            return false;
        }

        function getSelection () {
            return new GetSelection(global.document.selection);
        }

        module.exports = getSelection;

    }).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{"./rangeToTextRange":7}],6:[function(require,module,exports){
    'use strict';

    function isHostMethod (host, prop) {
        var type = typeof host[prop];
        return type === 'function' || !!(type === 'object' && host[prop]) || type === 'unknown';
    }

    function isHostProperty (host, prop) {
        return typeof host[prop] !== 'undefined';
    }

    function many (fn) {
        return function areHosted (host, props) {
            var i = props.length;
            while (i--) {
                if (!fn(host, props[i])) {
                    return false;
                }
            }
            return true;
        };
    }

    module.exports = {
        method: isHostMethod,
        methods: many(isHostMethod),
        property: isHostProperty,
        properties: many(isHostProperty)
    };

},{}],7:[function(require,module,exports){
    (function (global){
        'use strict';

        var doc = global.document;
        var body = doc.body;

        function rangeToTextRange (p) {
            if (p.collapsed) {
                return createBoundaryTextRange({ node: p.startContainer, offset: p.startOffset }, true);
            }
            var startRange = createBoundaryTextRange({ node: p.startContainer, offset: p.startOffset }, true);
            var endRange = createBoundaryTextRange({ node: p.endContainer, offset: p.endOffset }, false);
            var textRange = body.createTextRange();
            textRange.setEndPoint('StartToStart', startRange);
            textRange.setEndPoint('EndToEnd', endRange);
            return textRange;
        }

        function isCharacterDataNode (node) {
            var t = node.nodeType;
            return t === 3 || t === 4 || t === 8 ;
        }

        function createBoundaryTextRange (p, starting) {
            var bound;
            var parent;
            var offset = p.offset;
            var workingNode;
            var childNodes;
            var range = body.createTextRange();
            var data = isCharacterDataNode(p.node);

            if (data) {
                bound = p.node;
                parent = bound.parentNode;
            } else {
                childNodes = p.node.childNodes;
                bound = offset < childNodes.length ? childNodes[offset] : null;
                parent = p.node;
            }

            workingNode = doc.createElement('span');
            workingNode.innerHTML = '&#feff;';

            if (bound) {
                parent.insertBefore(workingNode, bound);
            } else {
                parent.appendChild(workingNode);
            }

            range.moveToElementText(workingNode);
            range.collapse(!starting);
            parent.removeChild(workingNode);

            if (data) {
                range[starting ? 'moveStart' : 'moveEnd']('character', offset);
            }
            return range;
        }

        module.exports = rangeToTextRange;

    }).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{}],8:[function(require,module,exports){
    'use strict';

    var getSelection = require('./getSelection');
    var setSelection = require('./setSelection');

    module.exports = {
        get: getSelection,
        set: setSelection
    };

},{"./getSelection":2,"./setSelection":9}],9:[function(require,module,exports){
    (function (global){
        'use strict';

        var getSelection = require('./getSelection');
        var rangeToTextRange = require('./rangeToTextRange');
        var doc = global.document;

        function setSelection (p) {
            if (doc.createRange) {
                modernSelection();
            } else {
                oldSelection();
            }

            function modernSelection () {
                var sel = getSelection();
                var range = doc.createRange();
                if (!p.startContainer) {
                    return;
                }
                if (p.endContainer) {
                    range.setEnd(p.endContainer, p.endOffset);
                } else {
                    range.setEnd(p.startContainer, p.startOffset);
                }
                range.setStart(p.startContainer, p.startOffset);
                sel.removeAllRanges();
                sel.addRange(range);
            }

            function oldSelection () {
                rangeToTextRange(p).select();
            }
        }

        module.exports = setSelection;

    }).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{"./getSelection":2,"./rangeToTextRange":7}],10:[function(require,module,exports){
    'use strict';

    var get = easyGet;
    var set = easySet;

    if (document.selection && document.selection.createRange) {
        get = hardGet;
        set = hardSet;
    }

    function easyGet (el) {
        return {
            start: el.selectionStart,
            end: el.selectionEnd
        };
    }

    function hardGet (el) {
        var active = document.activeElement;
        if (active !== el) {
            el.focus();
        }

        var range = document.selection.createRange();
        var bookmark = range.getBookmark();
        var original = el.value;
        var marker = getUniqueMarker(original);
        var parent = range.parentElement();
        if (parent === null || !inputs(parent)) {
            return result(0, 0);
        }
        range.text = marker + range.text + marker;

        var contents = el.value;

        el.value = original;
        range.moveToBookmark(bookmark);
        range.select();

        return result(contents.indexOf(marker), contents.lastIndexOf(marker) - marker.length);

        function result (start, end) {
            if (active !== el) { // don't disrupt pre-existing state
                if (active) {
                    active.focus();
                } else {
                    el.blur();
                }
            }
            return { start: start, end: end };
        }
    }

    function getUniqueMarker (contents) {
        var marker;
        do {
            marker = '@@marker.' + Math.random() * new Date();
        } while (contents.indexOf(marker) !== -1);
        return marker;
    }

    function inputs (el) {
        return ((el.tagName === 'INPUT' && el.type === 'text') || el.tagName === 'TEXTAREA');
    }

    function easySet (el, p) {
        el.selectionStart = parse(el, p.start);
        el.selectionEnd = parse(el, p.end);
    }

    function hardSet (el, p) {
        var range = el.createTextRange();

        if (p.start === 'end' && p.end === 'end') {
            range.collapse(false);
            range.select();
        } else {
            range.collapse(true);
            range.moveEnd('character', parse(el, p.end));
            range.moveStart('character', parse(el, p.start));
            range.select();
        }
    }

    function parse (el, value) {
        return value === 'end' ? el.value.length : value || 0;
    }

    function sell (el, p) {
        if (arguments.length === 2) {
            set(el, p);
        }
        return get(el);
    }

    module.exports = sell;

},{}],11:[function(require,module,exports){
    (function (global){
        'use strict';

        var sell = require('sell');
        var crossvent = require('crossvent');
        var seleccion = require('seleccion');
        var throttle = require('./throttle');
        var getSelection = seleccion.get;
        var props = [
            'direction',
            'boxSizing',
            'width',
            'height',
            'overflowX',
            'overflowY',
            'borderTopWidth',
            'borderRightWidth',
            'borderBottomWidth',
            'borderLeftWidth',
            'paddingTop',
            'paddingRight',
            'paddingBottom',
            'paddingLeft',
            'fontStyle',
            'fontVariant',
            'fontWeight',
            'fontStretch',
            'fontSize',
            'fontSizeAdjust',
            'lineHeight',
            'fontFamily',
            'textAlign',
            'textTransform',
            'textIndent',
            'textDecoration',
            'letterSpacing',
            'wordSpacing'
        ];
        var win = global;
        var doc = document;
        var ff = win.mozInnerScreenX !== null && win.mozInnerScreenX !== void 0;

        function tailormade (el, options) {
            var textInput = el.tagName === 'INPUT' || el.tagName === 'TEXTAREA';
            var throttledRefresh = throttle(refresh, 30);
            var o = options || {};

            bind();

            return {
                read: readPosition,
                refresh: throttledRefresh,
                destroy: destroy
            };

            function noop () {}
            function readPosition () { return (textInput ? coordsText : coordsHTML)(); }

            function refresh () {
                if (o.sleeping) {
                    return;
                }
                return (o.update || noop)(readPosition());
            }

            function coordsText () {
                var p = sell(el);
                var context = prepare();
                var readings = readTextCoords(context, p.start);
                doc.body.removeChild(context.mirror);
                return readings;
            }

            function coordsHTML () {
                var sel = getSelection();
                if (sel.rangeCount) {
                    var range = sel.getRangeAt(0);
                    var needsToWorkAroundNewlineBug = range.startContainer.nodeName === 'P' && range.startOffset === 0;
                    if (needsToWorkAroundNewlineBug) {
                        return {
                            x: range.startContainer.offsetLeft,
                            y: range.startContainer.offsetTop,
                            absolute: true
                        };
                    }
                    if (range.getClientRects) {
                        var rects = range.getClientRects();
                        if (rects.length > 0) {
                            return {
                                x: rects[0].left,
                                y: rects[0].top,
                                absolute: true
                            };
                        }
                    }
                }
                return { x: 0, y: 0 };
            }

            function readTextCoords (context, p) {
                var rest = doc.createElement('span');
                var mirror = context.mirror;
                var computed = context.computed;

                write(mirror, read(el).substring(0, p));

                if (el.tagName === 'INPUT') {
                    mirror.textContent = mirror.textContent.replace(/\s/g, '\u00a0');
                }

                write(rest, read(el).substring(p) || '.');

                mirror.appendChild(rest);

                return {
                    x: rest.offsetLeft + parseInt(computed['borderLeftWidth']),
                    y: rest.offsetTop + parseInt(computed['borderTopWidth'])
                };
            }

            function read (el) {
                return textInput ? el.value : el.innerHTML;
            }

            function prepare () {
                var computed = win.getComputedStyle ? getComputedStyle(el) : el.currentStyle;
                var mirror = doc.createElement('div');
                var style = mirror.style;

                doc.body.appendChild(mirror);

                if (el.tagName !== 'INPUT') {
                    style.wordWrap = 'break-word';
                }
                style.whiteSpace = 'pre-wrap';
                style.position = 'absolute';
                style.visibility = 'hidden';
                props.forEach(copy);

                if (ff) {
                    style.width = parseInt(computed.width) - 2 + 'px';
                    if (el.scrollHeight > parseInt(computed.height)) {
                        style.overflowY = 'scroll';
                    }
                } else {
                    style.overflow = 'hidden';
                }
                return { mirror: mirror, computed: computed };

                function copy (prop) {
                    style[prop] = computed[prop];
                }
            }

            function write (el, value) {
                if (textInput) {
                    el.textContent = value;
                } else {
                    el.innerHTML = value;
                }
            }

            function bind (remove) {
                var op = remove ? 'remove' : 'add';
                crossvent[op](el, 'keydown', throttledRefresh);
                crossvent[op](el, 'keyup', throttledRefresh);
                crossvent[op](el, 'input', throttledRefresh);
                crossvent[op](el, 'paste', throttledRefresh);
                crossvent[op](el, 'change', throttledRefresh);
            }

            function destroy () {
                bind(true);
            }
        }

        module.exports = tailormade;

    }).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{"./throttle":12,"crossvent":18,"seleccion":8,"sell":10}],12:[function(require,module,exports){
    'use strict';

    function throttle (fn, boundary) {
        var last = -Infinity;
        var timer;
        return function bounced () {
            if (timer) {
                return;
            }
            unbound();

            function unbound () {
                clearTimeout(timer);
                timer = null;
                var next = last + boundary;
                var now = Date.now();
                if (now > next) {
                    last = now;
                    fn();
                } else {
                    timer = setTimeout(unbound, next - now);
                }
            }
        };
    }

    module.exports = throttle;

},{}],13:[function(require,module,exports){
    'use strict';

    var ticky = require('ticky');

    module.exports = function debounce (fn, args, ctx) {
        if (!fn) { return; }
        ticky(function run () {
            fn.apply(ctx || null, args || []);
        });
    };

},{"ticky":16}],14:[function(require,module,exports){
    'use strict';

    var atoa = require('atoa');
    var debounce = require('./debounce');

    module.exports = function emitter (thing, options) {
        var opts = options || {};
        var evt = {};
        if (thing === undefined) { thing = {}; }
        thing.on = function (type, fn) {
            if (!evt[type]) {
                evt[type] = [fn];
            } else {
                evt[type].push(fn);
            }
            return thing;
        };
        thing.once = function (type, fn) {
            fn._once = true; // thing.off(fn) still works!
            thing.on(type, fn);
            return thing;
        };
        thing.off = function (type, fn) {
            var c = arguments.length;
            if (c === 1) {
                delete evt[type];
            } else if (c === 0) {
                evt = {};
            } else {
                var et = evt[type];
                if (!et) { return thing; }
                et.splice(et.indexOf(fn), 1);
            }
            return thing;
        };
        thing.emit = function () {
            var args = atoa(arguments);
            return thing.emitterSnapshot(args.shift()).apply(this, args);
        };
        thing.emitterSnapshot = function (type) {
            var et = (evt[type] || []).slice(0);
            return function () {
                var args = atoa(arguments);
                var ctx = this || thing;
                if (type === 'error' && opts.throws !== false && !et.length) { throw args.length === 1 ? args[0] : args; }
                et.forEach(function emitter (listen) {
                    if (opts.async) { debounce(listen, args, ctx); } else { listen.apply(ctx, args); }
                    if (listen._once) { thing.off(type, listen); }
                });
                return thing;
            };
        };
        return thing;
    };

},{"./debounce":13,"atoa":15}],15:[function(require,module,exports){
    module.exports = function atoa (a, n) { return Array.prototype.slice.call(a, n); }

},{}],16:[function(require,module,exports){
    var si = typeof setImmediate === 'function', tick;
    if (si) {
        tick = function (fn) { setImmediate(fn); };
    } else {
        tick = function (fn) { setTimeout(fn, 0); };
    }

    module.exports = tick;
},{}],17:[function(require,module,exports){
    (function (global){

        var NativeCustomEvent = global.CustomEvent;

        function useNative () {
            try {
                var p = new NativeCustomEvent('cat', { detail: { foo: 'bar' } });
                return  'cat' === p.type && 'bar' === p.detail.foo;
            } catch (e) {
            }
            return false;
        }

        /**
         * Cross-browser `CustomEvent` constructor.
         *
         * https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent.CustomEvent
         *
         * @public
         */

        module.exports = useNative() ? NativeCustomEvent :

            // IE >= 9
            'function' === typeof document.createEvent ? function CustomEvent (type, params) {
                    var e = document.createEvent('CustomEvent');
                    if (params) {
                        e.initCustomEvent(type, params.bubbles, params.cancelable, params.detail);
                    } else {
                        e.initCustomEvent(type, false, false, void 0);
                    }
                    return e;
                } :

                // IE <= 8
                function CustomEvent (type, params) {
                    var e = document.createEventObject();
                    e.type = type;
                    if (params) {
                        e.bubbles = Boolean(params.bubbles);
                        e.cancelable = Boolean(params.cancelable);
                        e.detail = params.detail;
                    } else {
                        e.bubbles = false;
                        e.cancelable = false;
                        e.detail = void 0;
                    }
                    return e;
                }

    }).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{}],18:[function(require,module,exports){
    (function (global){
        'use strict';

        var customEvent = require('custom-event');
        var eventmap = require('./eventmap');
        var doc = document;
        var addEvent = addEventEasy;
        var removeEvent = removeEventEasy;
        var hardCache = [];

        if (!global.addEventListener) {
            addEvent = addEventHard;
            removeEvent = removeEventHard;
        }

        function addEventEasy (el, type, fn, capturing) {
            return el.addEventListener(type, fn, capturing);
        }

        function addEventHard (el, type, fn) {
            return el.attachEvent('on' + type, wrap(el, type, fn));
        }

        function removeEventEasy (el, type, fn, capturing) {
            return el.removeEventListener(type, fn, capturing);
        }

        function removeEventHard (el, type, fn) {
            return el.detachEvent('on' + type, unwrap(el, type, fn));
        }

        function fabricateEvent (el, type, model) {
            var e = eventmap.indexOf(type) === -1 ? makeCustomEvent() : makeClassicEvent();
            if (el.dispatchEvent) {
                el.dispatchEvent(e);
            } else {
                el.fireEvent('on' + type, e);
            }
            function makeClassicEvent () {
                var e;
                if (doc.createEvent) {
                    e = doc.createEvent('Event');
                    e.initEvent(type, true, true);
                } else if (doc.createEventObject) {
                    e = doc.createEventObject();
                }
                return e;
            }
            function makeCustomEvent () {
                return new customEvent(type, { detail: model });
            }
        }

        function wrapperFactory (el, type, fn) {
            return function wrapper (originalEvent) {
                var e = originalEvent || global.event;
                e.target = e.target || e.srcElement;
                e.preventDefault = e.preventDefault || function preventDefault () { e.returnValue = false; };
                e.stopPropagation = e.stopPropagation || function stopPropagation () { e.cancelBubble = true; };
                e.which = e.which || e.keyCode;
                fn.call(el, e);
            };
        }

        function wrap (el, type, fn) {
            var wrapper = unwrap(el, type, fn) || wrapperFactory(el, type, fn);
            hardCache.push({
                wrapper: wrapper,
                element: el,
                type: type,
                fn: fn
            });
            return wrapper;
        }

        function unwrap (el, type, fn) {
            var i = find(el, type, fn);
            if (i) {
                var wrapper = hardCache[i].wrapper;
                hardCache.splice(i, 1); // free up a tad of memory
                return wrapper;
            }
        }

        function find (el, type, fn) {
            var i, item;
            for (i = 0; i < hardCache.length; i++) {
                item = hardCache[i];
                if (item.element === el && item.type === type && item.fn === fn) {
                    return i;
                }
            }
        }

        module.exports = {
            add: addEvent,
            remove: removeEvent,
            fabricate: fabricateEvent
        };

    }).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{"./eventmap":19,"custom-event":17}],19:[function(require,module,exports){
    (function (global){
        'use strict';

        var eventmap = [];
        var eventname = '';
        var ron = /^on/;

        for (eventname in global) {
            if (ron.test(eventname)) {
                eventmap.push(eventname.slice(2));
            }
        }

        module.exports = eventmap;

    }).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{}],20:[function(require,module,exports){
    'use strict';

    var isInput = require('./isInput');
    var bindings = {};

    function has (source, target) {
        var binding = bindings[source.id];
        return binding && binding[target.id];
    }

    function insert (source, target) {
        var binding = bindings[source.id];
        if (!binding) {
            binding = bindings[source.id] = {};
        }
        var invalidate = invalidator(target);
        binding[target.id] = invalidate;
        source.on('data', invalidate);
        source.on('destroyed', remove.bind(null, source, target));
    }

    function remove (source, target) {
        var binding = bindings[source.id];
        if (!binding) {
            return;
        }
        var invalidate = binding[target.id];
        source.off('data', invalidate);
        delete binding[target.id];
    }

    function invalidator (target) {
        return function invalidate () {
            target.refresh();
        };
    }

    function add (source, target) {
        if (isInput(target.associated) || has(source, target)) {
            return;
        }
        insert(source, target);
    }

    module.exports = {
        add: add,
        remove: remove
    };

},{"./isInput":30}],21:[function(require,module,exports){
    'use strict';

    var crossvent = require('crossvent');
    var emitter = require('contra/emitter');
    var dom = require('./dom');
    var text = require('./text');
    var parse = require('./parse');
    var clone = require('./clone');
    var defaults = require('./defaults');
    var momentum = require('./momentum');
    var classes = require('./classes');
    var noop = require('./noop');
    var no;

    function calendar (calendarOptions) {
        var o;
        var ref;
        var refCal;
        var container;
        var rendered = false;

        // date variables
        var monthOffsetAttribute = 'data-rome-offset';
        var weekdays;
        var weekdayCount;
        var calendarMonths = [];
        var lastYear;
        var lastMonth;
        var lastDay;
        var lastDayElement;
        var datewrapper;
        var back;
        var next;

        // time variables
        var secondsInDay = 60 * 60 * 24;
        var time;
        var timelist;

        var api = emitter({
            associated: calendarOptions.associated
        });

        init();
        setTimeout(ready, 0);

        return api;

        function napi () { return api; }

        function init (initOptions) {
            o = defaults(initOptions || calendarOptions, api);
            if (!container) { container = dom({ className: o.styles.container }); }
            weekdays = o.weekdayFormat;
            weekdayCount = weekdays.length;
            lastMonth = no;
            lastYear = no;
            lastDay = no;
            lastDayElement = no;
            o.appendTo.appendChild(container);

            removeChildren(container);
            rendered = false;
            ref = o.initialValue ? o.initialValue : momentum.moment();
            refCal = ref.clone();

            api.back = subtractMonth;
            api.container = container;
            api.destroyed = false;
            api.destroy = destroy.bind(api, false);
            api.emitValues = emitValues;
            api.getDate = getDate;
            api.getDateString = getDateString;
            api.getMoment = getMoment;
            api.hide = hide;
            api.next = addMonth;
            api.options = changeOptions;
            api.options.reset = resetOptions;
            api.refresh = refresh;
            api.restore = napi;
            api.setValue = setValue;
            api.show = show;

            eventListening();
            ready();

            return api;
        }

        function ready () {
            api.emit('ready', clone(o));
        }

        function destroy (silent) {
            if (container && container.parentNode) {
                container.parentNode.removeChild(container);
            }

            if (o) {
                eventListening(true);
            }

            var destroyed = api.emitterSnapshot('destroyed');
            api.back = noop;
            api.destroyed = true;
            api.destroy = napi;
            api.emitValues = napi;
            api.getDate = noop;
            api.getDateString = noop;
            api.getMoment = noop;
            api.hide = napi;
            api.next = noop;
            api.options = napi;
            api.options.reset = napi;
            api.refresh = napi;
            api.restore = init;
            api.setValue = napi;
            api.show = napi;
            api.off();

            if (silent !== true) {
                destroyed();
            }

            return api;
        }

        function eventListening (remove) {
            var op = remove ? 'remove' : 'add';
            if (o.autoHideOnBlur) { crossvent[op](document.documentElement, 'focus', hideOnBlur, true); }
            if (o.autoHideOnClick) { crossvent[op](document, 'click', hideOnClick); }
        }

        function changeOptions (options) {
            if (arguments.length === 0) {
                return clone(o);
            }
            destroy();
            init(options);
            return api;
        }

        function resetOptions () {
            return changeOptions({ appendTo: o.appendTo });
        }

        function render () {
            if (rendered) {
                return;
            }
            rendered = true;
            renderDates();
            renderTime();
            api.emit('render');
        }

        function renderDates () {
            if (!o.date) {
                return;
            }
            var i;
            calendarMonths = [];

            datewrapper = dom({ className: o.styles.date, parent: container });

            for (i = 0; i < o.monthsInCalendar; i++) {
                renderMonth(i);
            }

            crossvent.add(back, 'click', subtractMonth);
            crossvent.add(next, 'click', addMonth);
            crossvent.add(datewrapper, 'click', pickDay);

            function renderMonth (i) {
                var month = dom({ className: o.styles.month, parent: datewrapper });
                if (i === 0) {
                    back = dom({ type: 'button', className: o.styles.back, attributes: { type: 'button' }, parent: month });
                }
                if (i === o.monthsInCalendar -1) {
                    next = dom({ type: 'button', className: o.styles.next, attributes: { type: 'button' }, parent: month });
                }
                var label = dom({ className: o.styles.monthLabel, parent: month });
                var date = dom({ type: 'table', className: o.styles.dayTable, parent: month });
                var datehead = dom({ type: 'thead', className: o.styles.dayHead, parent: date });
                var dateheadrow = dom({ type: 'tr', className: o.styles.dayRow, parent: datehead });
                var datebody = dom({ type: 'tbody', className: o.styles.dayBody, parent: date });
                var j;

                for (j = 0; j < weekdayCount; j++) {
                    dom({ type: 'th', className: o.styles.dayHeadElem, parent: dateheadrow, text: weekdays[weekday(j)] });
                }

                datebody.setAttribute(monthOffsetAttribute, i);
                calendarMonths.push({
                    label: label,
                    body: datebody
                });
            }
        }

        function renderTime () {
            if (!o.time || !o.timeInterval) {
                return;
            }
            var timewrapper = dom({ className: o.styles.time, parent: container });
            time = dom({ className: o.styles.selectedTime, parent: timewrapper, text: ref.format(o.timeFormat) });
            crossvent.add(time, 'click', toggleTimeList);
            timelist = dom({ className: o.styles.timeList, parent: timewrapper });
            crossvent.add(timelist, 'click', pickTime);
            var next = momentum.moment('00:00:00', 'HH:mm:ss');
            var latest = next.clone().add(1, 'days');
            while (next.isBefore(latest)) {
                dom({ className: o.styles.timeOption, parent: timelist, text: next.format(o.timeFormat) });
                next.add(o.timeInterval, 'seconds');
            }
        }

        function weekday (index, backwards) {
            var factor = backwards ? -1 : 1;
            var offset = index + o.weekStart * factor;
            if (offset >= weekdayCount || offset < 0) {
                offset += weekdayCount * -factor;
            }
            return offset;
        }

        function displayValidTimesOnly () {
            if (!o.time || !rendered) {
                return;
            }
            var times = timelist.children;
            var length = times.length;
            var date;
            var time;
            var item;
            var i;
            for (i = 0; i < length; i++) {
                item = times[i];
                time = momentum.moment(text(item), o.timeFormat);
                date = setTime(ref.clone(), time);
                item.style.display = isInRange(date, false, o.timeValidator) ? 'block' : 'none';
            }
        }

        function toggleTimeList (show) {
            var display = typeof show === 'boolean' ? show : timelist.style.display === 'none';
            if (display) {
                showTimeList();
            } else {
                hideTimeList();
            }
        }

        function showTimeList () { if (timelist) { timelist.style.display = 'block'; } }
        function hideTimeList () { if (timelist) { timelist.style.display = 'none'; } }
        function showCalendar () { container.style.display = 'inline-block'; api.emit('show'); }
        function hideCalendar () {
            if (container.style.display !== 'none') {
                container.style.display = 'none';
                api.emit('hide');
            }
        }

        function show () {
            render();
            refresh();
            toggleTimeList(!o.date);
            showCalendar();
            return api;
        }

        function hide () {
            hideTimeList();
            setTimeout(hideCalendar, 0);
            return api;
        }

        function hideConditionally () {
            hideTimeList();

            var pos = classes.contains(container, o.styles.positioned);
            if (pos) {
                setTimeout(hideCalendar, 0);
            }
            return api;
        }

        function calendarEventTarget (e) {
            var target = e.target;
            if (target === api.associated) {
                return true;
            }
            while (target) {
                if (target === container) {
                    return true;
                }
                target = target.parentNode;
            }
        }

        function hideOnBlur (e) {
            if (calendarEventTarget(e)) {
                return;
            }
            hideConditionally();
        }

        function hideOnClick (e) {
            if (calendarEventTarget(e)) {
                return;
            }
            hideConditionally();
        }

        function subtractMonth () { changeMonth('subtract'); }
        function addMonth () { changeMonth('add'); }
        function changeMonth (op) {
            var bound;
            var direction = op === 'add' ? -1 : 1;
            var offset = o.monthsInCalendar + direction * getMonthOffset(lastDayElement);
            refCal[op](offset, 'months');
            bound = inRange(refCal.clone());
            ref = bound || ref;
            if (bound) { refCal = bound.clone(); }
            update();
            api.emit(op === 'add' ? 'next' : 'back', ref.month());
        }

        function update (silent) {
            updateCalendar();
            updateTime();
            if (silent !== true) { emitValues(); }
            displayValidTimesOnly();
        }

        function updateCalendar () {
            if (!o.date || !rendered) {
                return;
            }
            var y = refCal.year();
            var m = refCal.month();
            var d = refCal.date();
            if (d === lastDay && m === lastMonth && y === lastYear) {
                return;
            }
            var canStay = isDisplayed();
            lastDay = refCal.date();
            lastMonth = refCal.month();
            lastYear = refCal.year();
            if (canStay) { updateCalendarSelection(); return; }
            calendarMonths.forEach(updateMonth);
            renderAllDays();

            function updateMonth (month, i) {
                var offsetCal = refCal.clone().add(i, 'months');
                text(month.label, offsetCal.format(o.monthFormat));
                removeChildren(month.body);
            }
        }

        function updateCalendarSelection () {
            var day = refCal.date() - 1;
            selectDayElement(false);
            calendarMonths.forEach(function (cal) {
                var days;
                if (sameCalendarMonth(cal.date, refCal)) {
                    days = cast(cal.body.children).map(aggregate);
                    days = Array.prototype.concat.apply([], days).filter(inside);
                    selectDayElement(days[day]);
                }
            });

            function cast (like) {
                var dest = [];
                var i;
                for (i = 0; i < like.length; i++) {
                    dest.push(like[i]);
                }
                return dest;
            }

            function aggregate (child) {
                return cast(child.children);
            }

            function inside (child) {
                return !classes.contains(child, o.styles.dayPrevMonth) &&
                    !classes.contains(child, o.styles.dayNextMonth);
            }
        }

        function isDisplayed () {
            return calendarMonths.some(matches);

            function matches (cal) {
                if (!lastYear) { return false; }
                return sameCalendarMonth(cal.date, refCal);
            }
        }

        function sameCalendarMonth (left, right) {
            return left && right && left.year() === right.year() && left.month() === right.month();
        }

        function updateTime () {
            if (!o.time || !rendered) {
                return;
            }
            text(time, ref.format(o.timeFormat));
        }

        function emitValues () {
            api.emit('data', getDateString());
            api.emit('year', ref.year());
            api.emit('month', ref.month());
            api.emit('day', ref.day());
            api.emit('time', ref.format(o.timeFormat));
            return api;
        }

        function refresh () {
            lastYear = false;
            lastMonth = false;
            lastDay = false;
            update(true);
            return api;
        }

        function setValue (value) {
            var date = parse(value, o.inputFormat);
            if (date === null) {
                return;
            }
            ref = inRange(date) || ref;
            refCal = ref.clone();
            update(true);

            return api;
        }

        function removeChildren (elem, self) {
            while (elem && elem.firstChild) {
                elem.removeChild(elem.firstChild);
            }
            if (self === true) {
                elem.parentNode.removeChild(elem);
            }
        }

        function renderAllDays () {
            var i;
            for (i = 0; i < o.monthsInCalendar; i++) {
                renderDays(i);
            }
        }

        function renderDays (offset) {
            var month = calendarMonths[offset];
            var offsetCal = refCal.clone().add(offset, 'months');
            var total = offsetCal.daysInMonth();
            var current = offsetCal.month() !== ref.month() ? -1 : ref.date(); // -1 : 1..31
            var first = offsetCal.clone().date(1);
            var firstDay = weekday(first.day(), true); // 0..6
            var tr = dom({ type: 'tr', className: o.styles.dayRow, parent: month.body });
            var prevMonth = hiddenWhen(offset !== 0, [o.styles.dayBodyElem, o.styles.dayPrevMonth]);
            var nextMonth = hiddenWhen(offset !== o.monthsInCalendar - 1, [o.styles.dayBodyElem, o.styles.dayNextMonth]);
            var disabled = o.styles.dayDisabled;
            var lastDay;

            part({
                base: first.clone().subtract(firstDay, 'days'),
                length: firstDay,
                cell: prevMonth
            });

            part({
                base: first.clone(),
                length: total,
                cell: [o.styles.dayBodyElem],
                selectable: true
            });

            lastDay = first.clone().add(total, 'days');

            part({
                base: lastDay,
                length: weekdayCount - tr.children.length,
                cell: nextMonth
            });

            back.disabled = !isInRangeLeft(first, true);
            next.disabled = !isInRangeRight(lastDay, true);
            month.date = offsetCal.clone();

            function part (data) {
                var i, day, node;
                for (i = 0; i < data.length; i++) {
                    if (tr.children.length === weekdayCount) {
                        tr = dom({ type: 'tr', className: o.styles.dayRow, parent: month.body });
                    }
                    day = data.base.clone().add(i, 'days');
                    node = dom({
                        type: 'td',
                        parent: tr,
                        text: day.format(o.dayFormat),
                        className: validationTest(day, data.cell.join(' ').split(' ')).join(' ')
                    });
                    if (data.selectable && day.date() === current) {
                        selectDayElement(node);
                    }
                }
            }

            function validationTest (day, cell) {
                if (!isInRange(day, true, o.dateValidator)) { cell.push(disabled); }
                return cell;
            }

            function hiddenWhen (value, cell) {
                if (value) { cell.push(o.styles.dayConcealed); }
                return cell;
            }
        }

        function isInRange (date, allday, validator) {
            if (!isInRangeLeft(date, allday)) {
                return false;
            }
            if (!isInRangeRight(date, allday)) {
                return false;
            }
            var valid = (validator || Function.prototype).call(api, date.toDate());
            return valid !== false;
        }

        function isInRangeLeft (date, allday) {
            var min = !o.min ? false : (allday ? o.min.clone().startOf('day') : o.min);
            return !min || !date.isBefore(min);
        }

        function isInRangeRight (date, allday) {
            var max = !o.max ? false : (allday ? o.max.clone().endOf('day') : o.max);
            return !max || !date.isAfter(max);
        }

        function inRange (date) {
            if (o.min && date.isBefore(o.min)) {
                return inRange(o.min.clone());
            } else if (o.max && date.isAfter(o.max)) {
                return inRange(o.max.clone());
            }
            var value = date.clone().subtract(1, 'days');
            if (validateTowards(value, date, 'add')) {
                return inTimeRange(value);
            }
            value = date.clone();
            if (validateTowards(value, date, 'subtract')) {
                return inTimeRange(value);
            }
        }

        function inTimeRange (value) {
            var copy = value.clone().subtract(o.timeInterval, 'seconds');
            var times = Math.ceil(secondsInDay / o.timeInterval);
            var i;
            for (i = 0; i < times; i++) {
                copy.add(o.timeInterval, 'seconds');
                if (copy.date() > value.date()) {
                    copy.subtract(1, 'days');
                }
                if (o.timeValidator.call(api, copy.toDate()) !== false) {
                    return copy;
                }
            }
        }

        function validateTowards (value, date, op) {
            var valid = false;
            while (valid === false) {
                value[op](1, 'days');
                if (value.month() !== date.month()) {
                    break;
                }
                valid = o.dateValidator.call(api, value.toDate());
            }
            return valid !== false;
        }

        function pickDay (e) {
            var target = e.target;
            if (classes.contains(target, o.styles.dayDisabled) || !classes.contains(target, o.styles.dayBodyElem)) {
                return;
            }
            var day = parseInt(text(target), 10);
            var prev = classes.contains(target, o.styles.dayPrevMonth);
            var next = classes.contains(target, o.styles.dayNextMonth);
            var offset = getMonthOffset(target) - getMonthOffset(lastDayElement);
            ref.add(offset, 'months');
            if (prev || next) {
                ref.add(prev ? -1 : 1, 'months');
            }
            selectDayElement(target);
            ref.date(day); // must run after setting the month
            setTime(ref, inRange(ref) || ref);
            refCal = ref.clone();
            if (o.autoClose === true) { hideConditionally(); }
            update();
        }

        function selectDayElement (node) {
            if (lastDayElement) {
                classes.remove(lastDayElement, o.styles.selectedDay);
            }
            if (node) {
                classes.add(node, o.styles.selectedDay);
            }
            lastDayElement = node;
        }

        function getMonthOffset (elem) {
            var offset;
            while (elem && elem.getAttribute) {
                offset = elem.getAttribute(monthOffsetAttribute);
                if (typeof offset === 'string') {
                    return parseInt(offset, 10);
                }
                elem = elem.parentNode;
            }
            return 0;
        }

        function setTime (to, from) {
            to.hour(from.hour()).minute(from.minute()).second(from.second());
            return to;
        }

        function pickTime (e) {
            var target = e.target;
            if (!classes.contains(target, o.styles.timeOption)) {
                return;
            }
            var value = momentum.moment(text(target), o.timeFormat);
            setTime(ref, value);
            refCal = ref.clone();
            emitValues();
            updateTime();
            if ((!o.date && o.autoClose === true) || o.autoClose === 'time') {
                hideConditionally();
            } else {
                hideTimeList();
            }
        }

        function getDate () {
            return ref.toDate();
        }

        function getDateString (format) {
            return ref.format(format || o.inputFormat);
        }

        function getMoment () {
            return ref.clone();
        }
    }

    module.exports = calendar;

},{"./classes":22,"./clone":23,"./defaults":25,"./dom":26,"./momentum":31,"./noop":32,"./parse":33,"./text":45,"contra/emitter":14,"crossvent":18}],22:[function(require,module,exports){
    'use strict';

    var trim = /^\s+|\s+$/g;
    var whitespace = /\s+/;

    function classes (node) {
        return node.className.replace(trim, '').split(whitespace);
    }

    function set (node, value) {
        node.className = value.join(' ');
    }

    function add (node, value) {
        var values = remove(node, value);
        values.push(value);
        set(node, values);
    }

    function remove (node, value) {
        var values = classes(node);
        var i = values.indexOf(value);
        if (i !== -1) {
            values.splice(i, 1);
            set(node, values);
        }
        return values;
    }

    function contains (node, value) {
        return classes(node).indexOf(value) !== -1;
    }

    module.exports = {
        add: add,
        remove: remove,
        contains: contains
    };

},{}],23:[function(require,module,exports){
    'use strict';

    var momentum = require('./momentum');

// naïve implementation, specifically meant to clone `options` objects
    function clone (thing) {
        var copy = {};
        var value;

        for (var key in thing) {
            value = thing[key];

            if (!value) {
                copy[key] = value;
            } else if (momentum.isMoment(value)) {
                copy[key] = value.clone();
            } else if (value._isStylesConfiguration) {
                copy[key] = clone(value);
            } else {
                copy[key] = value;
            }
        }

        return copy;
    }

    module.exports = clone;

},{"./momentum":31}],24:[function(require,module,exports){
    'use strict';

    var index = require('./index');
    var input = require('./input');
    var inline = require('./inline');
    var isInput = require('./isInput');

    function core (elem, options) {
        var cal;
        var existing = index.find(elem);
        if (existing) {
            return existing;
        }

        if (isInput(elem)) {
            cal = input(elem, options);
        } else {
            cal = inline(elem, options);
        }
        index.assign(elem, cal);

        return cal;
    }

    module.exports = core;

},{"./index":27,"./inline":28,"./input":29,"./isInput":30}],25:[function(require,module,exports){
    'use strict';

    var parse = require('./parse');
    var isInput = require('./isInput');
    var momentum = require('./momentum');

    function defaults (options, cal) {
        var temp;
        var no;
        var o = options || {};
        if (o.autoHideOnClick === no) { o.autoHideOnClick = true; }
        if (o.autoHideOnBlur === no) { o.autoHideOnBlur = true; }
        if (o.autoClose === no) { o.autoClose = true; }
        if (o.appendTo === no) { o.appendTo = document.body; }
        if (o.appendTo === 'parent') {
            if (isInput(cal.associated)) {
                o.appendTo = cal.associated.parentNode;
            } else {
                throw new Error('Inline calendars must be appended to a parent node explicitly.');
            }
        }
        if (o.invalidate === no) { o.invalidate = true; }
        if (o.required === no) { o.required = false; }
        if (o.date === no) { o.date = true; }
        if (o.time === no) { o.time = true; }
        if (o.date === false && o.time === false) { throw new Error('At least one of `date` or `time` must be `true`.'); }
        if (o.inputFormat === no) {
            if (o.date && o.time) {
                o.inputFormat = 'YYYY-MM-DD HH:mm';
            } else if (o.date) {
                o.inputFormat = 'YYYY-MM-DD';
            } else {
                o.inputFormat = 'HH:mm';
            }
        }
        if (o.initialValue === no) {
            o.initialValue = null;
        } else {
            o.initialValue = parse(o.initialValue, o.inputFormat);
        }
        if (o.min === no) { o.min = null; } else { o.min = parse(o.min, o.inputFormat); }
        if (o.max === no) { o.max = null; } else { o.max = parse(o.max, o.inputFormat); }
        if (o.timeInterval === no) { o.timeInterval = 60 * 30; } // 30 minutes by default
        if (o.min && o.max) {
            if (o.max.isBefore(o.min)) {
                temp = o.max;
                o.max = o.min;
                o.min = temp;
            }
            if (o.date === true) {
                if (o.max.clone().subtract(1, 'days').isBefore(o.min)) {
                    throw new Error('`max` must be at least one day after `min`');
                }
            } else if (o.timeInterval * 1000 - o.min % (o.timeInterval * 1000) > o.max - o.min) {
                throw new Error('`min` to `max` range must allow for at least one time option that matches `timeInterval`');
            }
        }
        if (o.dateValidator === no) { o.dateValidator = Function.prototype; }
        if (o.timeValidator === no) { o.timeValidator = Function.prototype; }
        if (o.timeFormat === no) { o.timeFormat = 'HH:mm'; }
        if (o.weekStart === no) { o.weekStart = momentum.moment().weekday(0).day(); }
        if (o.weekdayFormat === no) { o.weekdayFormat = 'min'; }
        if (o.weekdayFormat === 'long') {
            o.weekdayFormat = momentum.moment.weekdays();
        } else if (o.weekdayFormat === 'short') {
            o.weekdayFormat = momentum.moment.weekdaysShort();
        } else if (o.weekdayFormat === 'min') {
            o.weekdayFormat = momentum.moment.weekdaysMin();
        } else if (!Array.isArray(o.weekdayFormat) || o.weekdayFormat.length < 7) {
            throw new Error('`weekdays` must be `min`, `short`, or `long`');
        }
        if (o.monthsInCalendar === no) { o.monthsInCalendar = 1; }
        if (o.monthFormat === no) { o.monthFormat = 'MMMM YYYY'; }
        if (o.dayFormat === no) { o.dayFormat = 'DD'; }
        if (o.styles === no) { o.styles = {}; }

        o.styles._isStylesConfiguration = true;

        var styl = o.styles;
        if (styl.back === no) { styl.back = 'rd-back'; }
        if (styl.container === no) { styl.container = 'rd-container'; }
        if (styl.positioned === no) { styl.positioned = 'rd-container-attachment'; }
        if (styl.date === no) { styl.date = 'rd-date'; }
        if (styl.dayBody === no) { styl.dayBody = 'rd-days-body'; }
        if (styl.dayBodyElem === no) { styl.dayBodyElem = 'rd-day-body'; }
        if (styl.dayPrevMonth === no) { styl.dayPrevMonth = 'rd-day-prev-month'; }
        if (styl.dayNextMonth === no) { styl.dayNextMonth = 'rd-day-next-month'; }
        if (styl.dayDisabled === no) { styl.dayDisabled = 'rd-day-disabled'; }
        if (styl.dayConcealed === no) { styl.dayConcealed = 'rd-day-concealed'; }
        if (styl.dayHead === no) { styl.dayHead = 'rd-days-head'; }
        if (styl.dayHeadElem === no) { styl.dayHeadElem = 'rd-day-head'; }
        if (styl.dayRow === no) { styl.dayRow = 'rd-days-row'; }
        if (styl.dayTable === no) { styl.dayTable = 'rd-days'; }
        if (styl.month === no) { styl.month = 'rd-month'; }
        if (styl.monthLabel === no) { styl.monthLabel = 'rd-month-label'; }
        if (styl.next === no) { styl.next = 'rd-next'; }
        if (styl.selectedDay === no) { styl.selectedDay = 'rd-day-selected'; }
        if (styl.selectedTime === no) { styl.selectedTime = 'rd-time-selected'; }
        if (styl.time === no) { styl.time = 'rd-time'; }
        if (styl.timeList === no) { styl.timeList = 'rd-time-list'; }
        if (styl.timeOption === no) { styl.timeOption = 'rd-time-option'; }

        return o;
    }

    module.exports = defaults;

},{"./isInput":30,"./momentum":31,"./parse":33}],26:[function(require,module,exports){
    'use strict';

    function dom (options) {
        var o = options || {};
        if (!o.type) { o.type = 'div'; }
        var elem = document.createElement(o.type);
        if (o.className) { elem.className = o.className; }
        if (o.text) { elem.innerText = elem.textContent = o.text; }
        if (o.attributes) {
            Object.keys(o.attributes).forEach(function(key) {
                elem.setAttribute(key, o.attributes[key]);
            });
        }
        if (o.parent) { o.parent.appendChild(elem); }
        return elem;
    }

    module.exports = dom;

},{}],27:[function(require,module,exports){
    'use strict';
    var no;
    var ikey = 'data-rome-id';
    var index = [];

    function find (thing) { // can be a DOM element or a number
        if (typeof thing !== 'number' && thing && thing.getAttribute) {
            return find(thing.getAttribute(ikey));
        }
        var existing = index[thing];
        if (existing !== no) {
            return existing;
        }
        return null;
    }

    function assign (elem, instance) {
        elem.setAttribute(ikey, instance.id = index.push(instance) - 1);
    }

    module.exports = {
        find: find,
        assign: assign
    };

},{}],28:[function(require,module,exports){
    'use strict';

    var calendar = require('./calendar');

    function inline (elem, calendarOptions) {
        var o = calendarOptions || {};

        o.appendTo = elem;
        o.associated = elem;

        var cal = calendar(o);
        cal.show();
        return cal;
    }

    module.exports = inline;

},{"./calendar":21}],29:[function(require,module,exports){
    'use strict';

    var crossvent = require('crossvent');
    var bullseye = require('bullseye');
    var throttle = require('./throttle');
    var clone = require('./clone');
    var defaults = require('./defaults');
    var calendar = require('./calendar');
    var momentum = require('./momentum');
    var classes = require('./classes');

    function inputCalendar (input, calendarOptions) {
        var o = calendarOptions || {};

        o.associated = input;

        var api = calendar(o);
        var throttledTakeInput = throttle(takeInput, 30);
        var ignoreInvalidation;
        var ignoreShow;
        var eye;

        init(o);

        return api;

        function init (initOptions) {
            o = defaults(initOptions || o, api);

            classes.add(api.container, o.styles.positioned);
            crossvent.add(api.container, 'mousedown', containerMouseDown);
            crossvent.add(api.container, 'click', containerClick);

            api.getDate = unrequire(api.getDate);
            api.getDateString = unrequire(api.getDateString);
            api.getMoment = unrequire(api.getMoment);

            if (o.initialValue) {
                input.value = o.initialValue.format(o.inputFormat);
            }

            eye = bullseye(api.container, input);
            api.on('data', updateInput);
            api.on('show', eye.refresh);

            eventListening();
            throttledTakeInput();
        }

        function destroy () {
            eventListening(true);
            eye.destroy();
            eye = null;
        }

        function eventListening (remove) {
            var op = remove ? 'remove' : 'add';
            crossvent[op](input, 'click', show);
            crossvent[op](input, 'touchend', show);
            crossvent[op](input, 'focusin', show);
            crossvent[op](input, 'change', throttledTakeInput);
            crossvent[op](input, 'keypress', throttledTakeInput);
            crossvent[op](input, 'keydown', throttledTakeInput);
            crossvent[op](input, 'input', throttledTakeInput);
            if (o.invalidate) { crossvent[op](input, 'blur', invalidateInput); }

            if (remove) {
                api.once('ready', init);
                api.off('destroyed', destroy);
            } else {
                api.off('ready', init);
                api.once('destroyed', destroy);
            }
        }

        function containerClick () {
            ignoreShow = true;
            input.focus();
            ignoreShow = false;
        }

        function containerMouseDown () {
            ignoreInvalidation = true;
            setTimeout(unignore, 0);

            function unignore () {
                ignoreInvalidation = false;
            }
        }

        function invalidateInput () {
            if (!ignoreInvalidation && !isEmpty()) {
                api.emitValues();
            }
        }

        function show () {
            if (ignoreShow) {
                return;
            }
            api.show();
        }

        function takeInput () {
            var value = input.value.trim();
            if (isEmpty()) {
                return;
            }
            var date = momentum.moment(value, o.inputFormat, o.strictParse);
            api.setValue(date);
        }

        function updateInput (data) {
            input.value = data;
        }

        function isEmpty () {
            return o.required === false && input.value.trim() === '';
        }

        function unrequire (fn) {
            return function maybe () {
                return isEmpty() ? null : fn.apply(this, arguments);
            };
        }
    }

    module.exports = inputCalendar;

},{"./calendar":21,"./classes":22,"./clone":23,"./defaults":25,"./momentum":31,"./throttle":46,"bullseye":1,"crossvent":18}],30:[function(require,module,exports){
    'use strict';

    function isInput (elem) {
        return elem && elem.nodeName && elem.nodeName.toLowerCase() === 'input';
    }

    module.exports = isInput;

},{}],31:[function(require,module,exports){
    'use strict';

    function isMoment (value) {
        return value && Object.prototype.hasOwnProperty.call(value, '_isAMomentObject');
    }

    var api = {
        moment: null,
        isMoment: isMoment
    };

    module.exports = api;

},{}],32:[function(require,module,exports){
    'use strict';

    function noop () {}

    module.exports = noop;

},{}],33:[function(require,module,exports){
    'use strict';

    var momentum = require('./momentum');

    function raw (date, format) {
        if (typeof date === 'string') {
            return momentum.moment(date, format);
        }
        if (Object.prototype.toString.call(date) === '[object Date]') {
            return momentum.moment(date);
        }
        if (momentum.isMoment(date)) {
            return date.clone();
        }
    }

    function parse (date, format) {
        var m = raw(date, typeof format === 'string' ? format : null);
        return m && m.isValid() ? m : null;
    }

    module.exports = parse;

},{"./momentum":31}],34:[function(require,module,exports){
    'use strict';

    if (!Array.prototype.filter) {
        Array.prototype.filter = function (fn, ctx) {
            var f = [];
            this.forEach(function (v, i, t) {
                if (fn.call(ctx, v, i, t)) { f.push(v); }
            }, ctx);
            return f;
        };
    }

},{}],35:[function(require,module,exports){
    'use strict';

    if (!Array.prototype.forEach) {
        Array.prototype.forEach = function (fn, ctx) {
            if (this === void 0 || this === null || typeof fn !== 'function') {
                throw new TypeError();
            }
            var t = this;
            var len = t.length;
            for (var i = 0; i < len; i++) {
                if (i in t) { fn.call(ctx, t[i], i, t); }
            }
        };
    }

},{}],36:[function(require,module,exports){
    'use strict';

    if (!Array.prototype.indexOf) {
        Array.prototype.indexOf = function (what, start) {
            if (this === undefined || this === null) {
                throw new TypeError();
            }
            var length = this.length;
            start = +start || 0;
            if (Math.abs(start) === Infinity) {
                start = 0;
            } else if (start < 0) {
                start += length;
                if (start < 0) { start = 0; }
            }
            for (; start < length; start++) {
                if (this[start] === what) {
                    return start;
                }
            }
            return -1;
        };
    }

},{}],37:[function(require,module,exports){
    'use strict';

    Array.isArray || (Array.isArray = function (a) {
        return '' + a !== a && Object.prototype.toString.call(a) === '[object Array]';
    });

},{}],38:[function(require,module,exports){
    'use strict';

    if (!Array.prototype.map) {
        Array.prototype.map = function (fn, ctx) {
            var context, result, i;

            if (this == null) {
                throw new TypeError('this is null or not defined');
            }

            var source = Object(this);
            var len = source.length >>> 0;

            if (typeof fn !== 'function') {
                throw new TypeError(fn + ' is not a function');
            }

            if (arguments.length > 1) {
                context = ctx;
            }

            result = new Array(len);
            i = 0;

            while (i < len) {
                if (i in source) {
                    result[i] = fn.call(context, source[i], i, source);
                }
                i++;
            }
            return result;
        };
    }

},{}],39:[function(require,module,exports){
    'use strict';

    if (!Array.prototype.some) {
        Array.prototype.some = function (fn, ctx) {
            var context, i;

            if (this == null) {
                throw new TypeError('this is null or not defined');
            }

            var source = Object(this);
            var len = source.length >>> 0;

            if (typeof fn !== 'function') {
                throw new TypeError(fn + ' is not a function');
            }

            if (arguments.length > 1) {
                context = ctx;
            }

            i = 0;

            while (i < len) {
                if (i in source) {
                    var test = fn.call(context, source[i], i, source);
                    if (test) {
                        return true;
                    }
                }
                i++;
            }
            return false;
        };
    }

},{}],40:[function(require,module,exports){
    'use strict';

    if (!Function.prototype.bind) {
        Function.prototype.bind = function (context) {
            if (typeof this !== 'function') {
                throw new TypeError('Function.prototype.bind - what is trying to be bound is not callable');
            }
            var curried = Array.prototype.slice.call(arguments, 1);
            var original = this;
            var NoOp = function () {};
            var bound = function () {
                var ctx = this instanceof NoOp && context ? this : context;
                var args = curried.concat(Array.prototype.slice.call(arguments));
                return original.apply(ctx, args);
            };
            NoOp.prototype = this.prototype;
            bound.prototype = new NoOp();
            return bound;
        };
    }

},{}],41:[function(require,module,exports){
    'use strict';

    var hasOwn = Object.prototype.hasOwnProperty;
    var hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString');
    var dontEnums = [
        'toString',
        'toLocaleString',
        'valueOf',
        'hasOwnProperty',
        'isPrototypeOf',
        'propertyIsEnumerable',
        'constructor'
    ];
    var dontEnumsLength = dontEnums.length;

    if (!Object.keys) {
        Object.keys = function(obj) {
            if (typeof obj !== 'object' && (typeof obj !== 'function' || obj === null)) {
                throw new TypeError('Object.keys called on non-object');
            }

            var result = [], prop, i;

            for (prop in obj) {
                if (hasOwn.call(obj, prop)) {
                    result.push(prop);
                }
            }

            if (hasDontEnumBug) {
                for (i = 0; i < dontEnumsLength; i++) {
                    if (hasOwn.call(obj, dontEnums[i])) {
                        result.push(dontEnums[i]);
                    }
                }
            }
            return result;
        };
    }

},{}],42:[function(require,module,exports){
    'use strict';

    if (!String.prototype.trim) {
        String.prototype.trim = function () {
            return this.replace(/^\s+|\s+$/g, '');
        };
    }

},{}],43:[function(require,module,exports){
    'use strict';

// these are only required for IE < 9
// maybe move to IE-specific distro?
    require('./polyfills/function.bind');
    require('./polyfills/array.foreach');
    require('./polyfills/array.map');
    require('./polyfills/array.filter');
    require('./polyfills/array.isarray');
    require('./polyfills/array.indexof');
    require('./polyfills/array.some');
    require('./polyfills/string.trim');
    require('./polyfills/object.keys');

    var core = require('./core');
    var index = require('./index');
    var use = require('./use');

    core.use = use.bind(core);
    core.find = index.find;
    core.val = require('./validators');

    module.exports = core;

},{"./core":24,"./index":27,"./polyfills/array.filter":34,"./polyfills/array.foreach":35,"./polyfills/array.indexof":36,"./polyfills/array.isarray":37,"./polyfills/array.map":38,"./polyfills/array.some":39,"./polyfills/function.bind":40,"./polyfills/object.keys":41,"./polyfills/string.trim":42,"./use":47,"./validators":48}],44:[function(require,module,exports){
    (function (global){
        var rome = require('./rome');
        var momentum = require('./momentum');

        rome.use(global.moment);

        if (momentum.moment === void 0) {
            throw new Error('rome depends on moment.js, you can get it at http://momentjs.com, or you could use the bundled distribution file instead.');
        }

        module.exports = rome;

    }).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{"./momentum":31,"./rome":43}],45:[function(require,module,exports){
    'use strict';

    function text (elem, value) {
        if (arguments.length === 2) {
            elem.innerText = elem.textContent = value;
        }
        return elem.innerText || elem.textContent;
    }

    module.exports = text;

},{}],46:[function(require,module,exports){
    'use strict';

    module.exports = function throttle (fn, boundary) {
        var last = -Infinity;
        var timer;
        return function bounced () {
            if (timer) {
                return;
            }
            unbound();

            function unbound () {
                clearTimeout(timer);
                timer = null;
                var next = last + boundary;
                var now = +new Date();
                if (now > next) {
                    last = now;
                    fn.apply(this, arguments);
                } else {
                    timer = setTimeout(unbound, next - now);
                }
            }
        };
    };

},{}],47:[function(require,module,exports){
    'use strict';

    var momentum = require('./momentum');

    function use (moment) {
        this.moment = momentum.moment = moment;
    }

    module.exports = use;

},{"./momentum":31}],48:[function(require,module,exports){
    'use strict';

    var index = require('./index');
    var parse = require('./parse');
    var association = require('./association');

    function compareBuilder (compare) {
        return function factory (value) {
            var fixed = parse(value);

            return function validate (date) {
                var cal = index.find(value);
                var left = parse(date);
                var right = fixed || cal && cal.getMoment();
                if (!right) {
                    return true;
                }
                if (cal) {
                    association.add(this, cal);
                }
                return compare(left, right);
            };
        };
    }

    function rangeBuilder (how, compare) {
        return function factory (start, end) {
            var dates;
            var len = arguments.length;

            if (Array.isArray(start)) {
                dates = start;
            } else {
                if (len === 1) {
                    dates = [start];
                } else if (len === 2) {
                    dates = [[start, end]];
                }
            }

            return function validate (date) {
                return dates.map(expand.bind(this))[how](compare.bind(this, date));
            };

            function expand (value) {
                var start, end;
                var cal = index.find(value);
                if (cal) {
                    start = end = cal.getMoment();
                } else if (Array.isArray(value)) {
                    start = value[0]; end = value[1];
                } else {
                    start = end = value;
                }
                if (cal) {
                    association.add(cal, this);
                }
                return {
                    start: parse(start).startOf('day').toDate(),
                    end: parse(end).endOf('day').toDate()
                };
            }
        };
    }

    var afterEq  = compareBuilder(function (left, right) { return left >= right; });
    var after    = compareBuilder(function (left, right) { return left  > right; });
    var beforeEq = compareBuilder(function (left, right) { return left <= right; });
    var before   = compareBuilder(function (left, right) { return left  < right; });

    var except   = rangeBuilder('every', function (left, right) { return right.start  > left || right.end  < left; });
    var only     = rangeBuilder('some',  function (left, right) { return right.start <= left && right.end >= left; });

    module.exports = {
        afterEq: afterEq,
        after: after,
        beforeEq: beforeEq,
        before: before,
        except: except,
        only: only
    };

},{"./association":20,"./index":27,"./parse":33}]},{},[44])(44)
});
