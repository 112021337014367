$(document).ready(function () {
    /**
     * Set media queries
     */
    var mq1170 = window.matchMedia( "(max-width: 1170px)" );
    var mq1120 = window.matchMedia( "(max-width: 1120px)" );
    var mq1023 = window.matchMedia( "(max-width: 1023px)" );
    var mq920 = window.matchMedia( "(max-width: 920px)" );
    var mq888 = window.matchMedia( "(max-width: 888px)" );
    var mq780 = window.matchMedia( "(max-width: 780px)" );

    /**
     * Main navigation
     */
    $('.main-nav .top-level > li:gt(4)').addClass('invert-position');

    $('.mobile-search').on('click', function (event) {
        $(this).parents('.top-bar').toggleClass('search-open');
        event.preventDefault();
    });
    $('.main-nav').on('click', '.mobile-menu', function (event) {
        $(this).parent().find('.top-level, .additional-menu-items').toggleClass('visible');
        event.preventDefault();
    });
    if (mq888.matches) {
        $('li.level-1').on('click', '> a', function (event) {
            event.preventDefault();
            $(this).parent().toggleClass('visible');
        })
    }


    /**
     * Embed PDF documents
     */
    $('a.embed-pdf').each(function(index) {
        var fallbackLink = $(this).clone().wrap('<p/>').parent().html();
        var href = $(this).attr('href');
        if (href.match(/^.*\.pdf$/)) {
            var options = {
                fallbackLink: fallbackLink
            };
            embedClassElement = "embed-pdf-container embed-pdf-container-" + index;
            embedClassCssPath = ".embed-pdf-container.embed-pdf-container-" + index;
            $(this).replaceWith('<div class="' + embedClassElement + '" />');
            PDFObject.embed(href, embedClassCssPath, options);
        }
    });


    /**
     * General link to be opened in a lightbox (for usage in the CMS system)
     */
    $('a.image-overlay').featherlight("image");

    $('a.image-gallery').featherlightGallery();


    /**
     * Toggle weather station widget
     */
    $('.current-weather-widget').on('click', '.selector', function (event) {
        event.preventDefault();
        event.stopPropagation();

        $(this).siblings('.selections').toggleClass('visible');
    });
    $('.current-weather-widget').on('click', '.selections li', function (event) {
        event.preventDefault();
        event.stopPropagation();

        var station_code = $(this).data('station-code');
        var form = $(this).parents('.current-weather-widget').find('form');
        form.find('input[name=station_code]').val(station_code);
        form.submit();

        $(this).parent().toggleClass('visible');
    });
    $(window).click(function() {
        $('.current-weather-widget .selections').removeClass('visible');
    });

    $('.forecast-area-list').on('click', 'li a', function (event) {
        event.preventDefault();

        $(this).parent().siblings().removeClass('selected-area');
        $(this).parent().addClass('selected-area');
    });

    $(".accordion-title").on("click", "a", function (event) {
        event.preventDefault();
        $(this).parent().siblings(".accordion-content").toggleClass("open");
    });

    if (mq780.matches) {
        $('.weather-map').on('click', 'li', function (event) {
            event.preventDefault();
            $(this).toggleClass('visible');
        })
    }


    /**
     * Satellite for starting page
     */
    $(".radar-satellite ul").slick({
        prevArrow: ".radar-satellite .slide-left",
        nextArrow: ".radar-satellite .slide-right"
    });
    /* $(".radar-satellite ul").slider({
        nextSlide: ".slide-right",
        prevSlide: ".slide-left",
    }); */


    /**
     * Webcams for starting page
     */
    if (mq920.matches) {
        $(".webcams ul").slick({
            prevArrow: ".webcams .slide-left",
            nextArrow: ".webcams .slide-right"
        });
    } else if (mq1120.matches) {
        $(".webcams ul").slick({
            prevArrow: ".webcams .slide-left",
            nextArrow: ".webcams .slide-right",
            slidesToShow: 2,
            slidesToScroll: 2
        });
    } else {
        $(".webcams ul").slick({
            prevArrow: ".webcams .slide-left",
            nextArrow: ".webcams .slide-right"
        });
    }
    /* $(".webcams ul").slider({
        nextSlide: ".slide-right",
        prevSlide: ".slide-left",
    }); */


    /**
     * Date/time picker for archive page
     */
    moment.locale('ro');
    if ($("#query-date").length > 0) {
        rome(
            $("#query-date").get(0),
            {
                max: moment().format('YYYY-MM-DD'),
                weekStart: 1,
                time: false
            }
        );
    }
    if ($("#query-time").length > 0) {
        rome(
            $("#query-time").get(0),
            {
                timeFormat: "HH:00",
                weekStart: 1,
                time: true,
                date: false,
                timeInterval: 3600
            }
        );
    }


    /**
     * Loops for starting page
     */
    $(".start-sat-cc-loop").click(function (event) {
        event.preventDefault();

        var loopPlayer = $('.sat-cc-loop-player').loopPlayer();
    });

    $(".start-sat-ir-loop").click(function (event) {
        event.preventDefault();

        var loopPlayer = $('.sat-ir-loop-player').loopPlayer();
    });

    /**
     * Selection of description codes
     */
    $(".selections").on("change", "select", function (event) {
        event.preventDefault();

        var uri = $(this).find(":selected").attr("value");
        $.get(uri, function (data) {
            var content = $(data).filter(".extractable-content").html();
            $.featherlight(content);
        });
    });

    /**
     * Selection of description codes
     */
    $('.in-page-charts').each(function (index) {
        var groupCode = $(this).data('group-code');
        var stationCode = $(this).data('station-code');
        var uri = '/index.php/maps/data/' + groupCode + '/' + stationCode + '';
        var that = this;
        $.get(uri, {}, function(response) {
            if (typeof response == 'string') {
                response = $.parseJSON(response);
            }

            var yAxisConfig = [];
            var seriesConfig = [];
            response.forEach(function(element, index) {
                yAxisConfig.push({
                    labels: {
                        format: "{value}", // + element.parameter_unit,
                        style: {
                            color: element.parameter_color
                        }
                    },
                    title: {
                        text: null
                    },
                    visible: index <= 0,
                    plotLines: element.tresholds.map(function (element) {
                        return {
                            value: element.treshold_value,
                            color: element.treshold_color,
                            label: {
                                text: element.treshold_name_ro
                            },
                            width: 2,
                            zIndex: 5
                        }
                    })
                });

                seriesConfig.push({
                    name: element.parameter_name_ro + (element.parameter_unit != '' ? ' (' + element.parameter_unit + ')' : ''),
                    color: element.parameter_color,
                    type: element.parameter_type == 'column' ? 'column' : 'spline',
                    yAxis: index,
                    visible: index <= 0,
                    tooltip: {
                        valueSuffix: ' ' + element.parameter_unit
                    },
                    data: element.values.map(function (element) {
                        var date = Date.UTC(element[0][0], element[0][1], element[0][2], element[0][3], element[0][4], element[0][5]);
                        return [date, element[1]];
                    })
                });
            });

            var chartOptions = {
                chart: {
                    borderWidth: 2,
                    renderTo: $(that).get(0),
                    zoomType: 'x',
                 },
                title: {
                    text: location.name,
                    x: -20 //center
                },
                xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: { // don't display the dummy year
                        month: '%e. %b',
                        year: '%b'
                    },
                },
                yAxis: yAxisConfig,
                legend: {
                    layout: 'vertical',
                    align: 'center',
                    verticalAlign: 'top',
                    floating: false,
                    borderWidth: 0,
                    maxHeight: 200
                },
                series: seriesConfig,
                 plotOptions: {
                    series: {
                        events: {
                            legendItemClick: function () {
                                if (this.yAxis.visible) {
                                    this.yAxis.update({
                                        visible: false
                                    });
                                } else {
                                    this.yAxis.update({
                                        visible: true
                                    });
                                }
                            }
                        }
                    }
                }
            };
            chart = new Highcharts.Chart(chartOptions);
        })
    });

    /**
     * Warnings SVG event handling
     */
    /*$("#warnings-map").on("load", function (event) {
        var provinces = $(this).find("g#MD path");
        provinces.on("mouseover", function (mouseoverEvent) {
            mouseoverEvent.preventDefault();

            var areaNameRo = $(this).attr("meteo_md:name_ro");
            $(".tooltip").html(areaNameRo).show();
        });
        provinces.on("mousemove", function (mousemoveEvent) {
            $(".tooltip").css('left', mousemoveEvent.pageX + 15).css('top', mousemoveEvent.pageY + 15);
        });
    });*/

    /**
     * Map filter settings
     * @type {any}
     */
    var locations = [];
    $('.map-stations li input:checked').each(function(index) {
        var stationCode = $(this).parents('.map-station').data('station-code');
        var stationName = $(this).parents('.map-station').find('h3 span').html();
        var longitude = parseFloat($(this).parents('.map-station').data('longitude'));
        var latitude = parseFloat($(this).parents('.map-station').data('latitude'));
        var marker = $(this).parents('.map-station').data('marker');
        var groupCode = $(this).parents('.map-group').data('group-code');
        locations.push({
            longitude: longitude,
            latitude: latitude,
            name: stationName,
            groupCode: groupCode,
            stationCode: stationCode,
            marker: marker
        });
    });
    var dataMap = $('.data-map').dataMap({
        locations: locations
    });
    $('.map-filter').on('click', '.map-group h2', function() {
        $(this).parent().toggleClass('unfolded');
        $(this).siblings('.map-stations').slideToggle();
    });
    $('.map-filter').on('change', '.map-stations li input', function() {
        var stationCode = $(this).parents('.map-station').data('station-code');
        var stationName = $(this).parents('.map-station').find('h3 span').html();
        var longitude = parseFloat($(this).parents('.map-station').data('longitude'));
        var latitude = parseFloat($(this).parents('.map-station').data('latitude'));
        var marker = $(this).parents('.map-station').data('marker');
        var groupCode = $(this).parents('.map-group').data('group-code');

        if (!this.checked) {
            dataMap.removeMarker({
                longitude: longitude,
                latitude: latitude,
                name: stationName,
                groupCode: groupCode,
                stationCode: stationCode,
                marker: marker
            });
        } else {
            dataMap.addMarker({
                longitude: longitude,
                latitude: latitude,
                name: stationName,
                groupCode: groupCode,
                stationCode: stationCode,
                marker: marker
            });
        }
    });
    $('.deselect-all').on('click', function (event) {
        event.preventDefault();
        $('.map-station input:checked').each(function (element) {
            $(this).removeAttr('checked');
            $(this).trigger('change');
        })
    });


    var dataMapChisinauStations = JSON.parse($("#data-map-chisinau-stations").html());
    var dataMapChisinau = $('.data-map-chisinau').dataMap({
        locations: dataMapChisinauStations.map(function (element) {
            return {
                longitude: element.station_longitude,
                latitude: element.station_latitude,
                name: element.station_name,
                groupCode: element.group_code,
                stationCode: element.station_code,
                marker: element.station_marker
            };
        }),
        center: [47.02403581, 28.85215759],
        minZoom: 13,
        maxZoom: 15
    });
});

$(window).on("load", function (event) {
    var warningObject = document.getElementById("warnings-map");
    if (warningObject) {
        var svgDocument;
        try {
            svgDocument = warningObject.contentDocument;
        }
        catch (e) {
            try {
                svgDocument = warningObject.getSVGDocument();
            } catch (e) {
            }
        }
        var meteoNS = "http://www.meteo.md";
        var paths = svgDocument.querySelectorAll("g#MD path");
        var borders = svgDocument.querySelectorAll("g#MD_Grenze path");
        var tooltips = document.getElementsByClassName("tooltip");
        for (var i = 0; i < paths.length; i++) {
            paths[i].addEventListener("click", function (event) {
                var areaId = this.className.baseVal.replace(/^.*area_(\d+).*$/, "$1");
                $(".warnings-timeline .area > li").hide();
                $(".warnings-timeline .area_" + areaId).show();
            }, false);
            paths[i].addEventListener("mouseover", function (event) {
                var areaNameRo = this.getAttributeNS(meteoNS, "name_ro");
                var areaNameEn = this.getAttributeNS(meteoNS, "name_en");
                var areaNameRu = this.getAttributeNS(meteoNS, "name_ru");
                var tooltip = $(".tooltip");
                if (tooltip.data("language-code") == "en") {
                    $(".tooltip").html(areaNameEn).show();
                } else if (tooltip.data("language-code") == "ru") {
                    $(".tooltip").html(areaNameRu).show();
                } else {
                    $(".tooltip").html(areaNameRo).show();
                }
            }, false);
            if (tooltips.length == 1) {
                var tooltip = tooltips.item(0);
                paths[i].addEventListener("mousemove", function (event) {
                    $(tooltip).css('left', event.clientX + 15).css('top', event.clientY + 15);
                }, false);
            }
        }
        var border = borders.item(0);
        border.addEventListener("mouseleave", function (event) {
            $(".tooltip").hide();
        }, false);
    }

    warningObject = document.getElementById("env-warnings-map");
    if (warningObject) {
        try {
            svgDocument = warningObject.contentDocument;
        }
        catch (e) {
            try {
                svgDocument = warningObject.getSVGDocument();
            } catch (e) {
            }
        }
        var circles = svgDocument.querySelectorAll("g#Circles circle");
        for (var i = 0; i < circles.length; i++) {
            circles[i].addEventListener("click", function (event) {
                var regionCode = this.className.baseVal.replace(/^([^ ]+).*$/, "$1");
                $(".warnings-timeline .region > li").hide();
                $(".warnings-timeline .region_" + regionCode).show();
            }, false);
        }
    }

    warningObject = document.getElementById("hydro-warnings-map");
    if (warningObject) {
        try {
            svgDocument = warningObject.contentDocument;
        }
        catch (e) {
            try {
                svgDocument = warningObject.getSVGDocument();
            } catch (e) {
            }
        }
        var meteoNS = "http://www.meteo.md";
        var polygons = svgDocument.querySelectorAll("g#sub_x5F_cathments polygon");
        var tooltips = document.getElementsByClassName("tooltip");
        for (var i = 0; i < polygons.length; i++) {
            polygons[i].addEventListener("click", function (event) {
                var regionCode = this.className.baseVal.replace(/^([^ ]+).*$/, "$1");
                $(".warnings-timeline .region > li").hide();
                $(".warnings-timeline .region_" + regionCode).show();
            }, false);
            polygons[i].addEventListener("mouseover", function (event) {
                var areaNameRo = this.getAttributeNS(meteoNS, "name_ro");
                $(".tooltip").html(areaNameRo).show();
            }, false);
            if (tooltips.length == 1) {
                var tooltip = tooltips.item(0);
                polygons[i].addEventListener("mousemove", function (event) {
                    $(tooltip).css('left', event.clientX + 15).css('top', event.clientY + 15);
                }, false);
            }
        }
    }
});