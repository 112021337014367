/**
 * Created by chris on 03.11.16.
 */

;(function($) {
    $.fn.dataMap = function(options) {
        var elements = this;
        const mq_1120 = window.matchMedia( "(max-width: 1120px)" );
        const mq_888 = window.matchMedia( "(max-width: 888px)" );
        const mq_690 = window.matchMedia( "(max-width: 690px)" );

        var minZoom = 8;
        if (mq_690.matches || (mq_1120.matches && !mq_888.matches)) {
            minZoom = 7
        }

        this.defaultOptions = {
            center: [46.99524111, 28.43261719],
            minZoom: minZoom,
            maxZoom: 14,
            locations: []
        };
        var settings = $.extend({}, this.defaultOptions, options);

        var markers = {};
        var dataMapElements = [];
        var mapCenter = new google.maps.LatLng(settings.center[0], settings.center[1]);
        var locations = settings.locations;
        var locationLength = locations.length;
        var mapStyling = [
            {
                featureType: "all",
                elementType: "all",
                stylers: [
                    //{ saturation: -100 },
                    //{ lightness: 20 }
                    { saturation: -40 },
                    { hue: "#006cc3" },
                    { lightness: 20 }
                ]
            }
        ];

        this.each(function() {
            var $this = $(this);
            var $element = $this[0];
            var dataMapElement;

            dataMapElements.push(dataMapElement = new google.maps.Map($element, {
                zoom: settings.minZoom,
                minZoom: settings.minZoom,
                maxZoom: settings.maxZoom,
                center: mapCenter,
                mapTypeId: google.maps.MapTypeId.TERRAIN,
                draggable: true,
                zoomControl: true,
                scrollwheel: false,
                disableDoubleClickZoom: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                styles: mapStyling
            }));

            //for (var i = 0; i < locationLength; i++) {
            locations.forEach(function (location, i) {
                var marker = markers[location.groupCode + ':' + location.stationCode] = new google.maps.Marker({
                    position: new google.maps.LatLng(location.latitude, location.longitude),
                    map: dataMapElement,
                    title: location.name,
                    icon: (location.marker == '') ? '/images/layout/map-marker.png' : location.marker
                });

                var infowindow = new google.maps.InfoWindow();
                var chartContainer = '<div class="chart-container ' + location.stationCode + '" style="height:300px; width:600px"></div>';
                google.maps.event.addListener(infowindow, 'domready', function() {
                    var chart;
                    $.get('/index.php/maps/data/' + location.groupCode + '/' + location.stationCode, {}, function(response) {
                        if (typeof response == 'string') {
                            response = $.parseJSON(response);
                        }

                        var yAxisConfig = [];
                        var seriesConfig = [];
                        response.forEach(function(element, index) {
                            yAxisConfig.push({
                                labels: {
                                    format: "{value}", // + element.parameter_unit,
                                    style: {
                                        color: element.parameter_color
                                    }
                                },
                                title: {
                                    //text: element.parameter_name_ro + ' (' + element.parameter_unit + ')'
                                    text: null
                                },
                                visible: index <= 0,
                                plotLines: element.tresholds.map(function (element) {
                                    return {
                                        value: element.treshold_value,
                                        color: element.treshold_color,
                                        label: {
                                            text: element.treshold_name_ro
                                        },
                                        width: 2,
                                        zIndex: 5
                                    }
                                })
                            });

                            seriesConfig.push({
                                name: element.parameter_name_ro + (element.parameter_unit != '' ? ' (' + element.parameter_unit + ')' : ''),
                                color: element.parameter_color,
                                type: element.parameter_type == 'column' ? 'column' : 'spline',
                                yAxis: index,
                                visible: index <= 0,
                                tooltip: {
                                    valueSuffix: ' ' + element.parameter_unit
                                },
                                data: element.values.map(function (element) {
                                    var date = Date.UTC(element[0][0], element[0][1], element[0][2], element[0][3], element[0][4], element[0][5]);
                                    return [date, element[1]];
                                })
                            });
                        });

                        chartOptions = {
                            chart: {
                                borderWidth: 2,
                                renderTo: $('.' + location.stationCode).get(0),
                                zoomType: 'x',
                                //type: chartType,
                                //height:300,
                                width:600,
                                //marginRight:40
                            },
                            title: {
                                text: location.name,
                                x: -20 //center
                            },
                            /*subtitle: {
                                text: location.name,
                                x: -20
                            },*/
                            xAxis: {
                                type: 'datetime',
                                dateTimeLabelFormats: { // don't display the dummy year
                                    month: '%e. %b',
                                    year: '%b'
                                },
                            },
                            /* yAxis: {
                                title: {
                                    text: location.parameterName + ' (' + location.unit + ')'
                                },
                                plotLines: tresholds
                            }, */
                            yAxis: yAxisConfig,
                            legend: {
                                layout: 'vertical',
                                align: 'center',
                                verticalAlign: 'top',
                                floating: false,
                                borderWidth: 0,
                                maxHeight: 200
                            },
                            series: seriesConfig,
                            /* series: [{
                             name: loc.name,
                             color: loc.color,
                             data: chartData
                             }] */
                            plotOptions: {
                                series: {
                                    events: {
                                        legendItemClick: function () {
                                            if (this.yAxis.visible) {
                                                this.yAxis.update({
                                                    visible: false
                                                });
                                            } else {
                                                this.yAxis.update({
                                                    visible: true
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        };
                        console.log("Chart Options:");
                        console.log(chartOptions);
                        chart = new Highcharts.Chart(chartOptions);
                    });
                });
                google.maps.event.addListener(marker, 'click', (function (marker, content, infowindow) {
                    return function () {
                        infowindow.setContent(content);
                        infowindow.open(dataMapElement, marker);
                    };
                })(marker, chartContainer, infowindow));
            });
        });

        return {
            addMarker: function(loc) {
                console.log("Called API method 'addMarker'");
                //for (var i = 0; i < dataMapElements.length; i++) {
                dataMapElements.forEach(function (dataMapElement, i) {
                    //var dataMapElement = dataMapElements[i];
                    var marker = markers[loc.groupCode + ':' + loc.stationCode] = new google.maps.Marker({
                        position: new google.maps.LatLng(loc.latitude, loc.longitude),
                        map: dataMapElement,
                        title: loc.name,
                        icon: (loc.marker === undefined) ? '/images/layout/map-marker.png' : loc.marker
                    });

                    var infowindow = new google.maps.InfoWindow();
                    var chartContainer = '<div class="chart-container ' + loc.stationCode + '" style="height:500px; width:600px"></div>';
                    google.maps.event.addListener(infowindow, 'domready', function() {
                        var chart;
                        $.get('/index.php/maps/data/' + loc.groupCode + '/' + loc.stationCode, {}, function(response) {
                            if (typeof response == 'string') {
                                response = $.parseJSON(response);
                            }

                            var yAxisConfig = [];
                            var seriesConfig = [];
                            response.forEach(function(element, index) {
                                yAxisConfig.push({
                                    labels: {
                                        format: "{value}", // + element.parameter_unit,
                                        style: {
                                            color: element.parameter_color
                                        }
                                    },
                                    title: {
                                        text: null
                                    },
                                    plotLines: element.tresholds.map(function (element) {
                                        return {
                                            value: element.treshold_value,
                                            color: element.treshold_color,
                                            label: {
                                                text: element.treshold_name_ro
                                            },
                                            width: 2,
                                            zIndex: 5
                                        }
                                    }),
                                    visible: index <= 0
                                });

                                seriesConfig.push({
                                    name: element.parameter_name_ro + (element.parameter_unit != '' ? ' (' + element.parameter_unit + ')' : ''),
                                    color: element.parameter_color,
                                    type: element.parameter_type == 'column' ? 'column' : 'spline',
                                    yAxis: index,
                                    visible: index <= 0,
                                    tooltip: {
                                        valueSuffix: ' ' + element.parameter_unit
                                    },
                                    data: element.values.map(function (element) {
                                        var date = Date.UTC(element[0][0], element[0][1], element[0][2], element[0][3], element[0][4], element[0][5]);
                                        return [date, element[1]];
                                    })
                                });
                            });

                            /* console.log(seriesConfig);

                            var chartData = response.map(function(element) {
                                var date = Date.UTC(element[0][0], element[0][1], element[0][2], element[0][3], element[0][4], element[0][5]);
                                return [date, element[1]];
                            });
                            console.log("Chart Data:");
                            console.log(chartData);

                            var chartType = 'spline';
                            if (loc.type == 'column') {
                                chartType = 'column';
                            }
                            var tresholds = [];
                            var numberTresholds = loc.tresholds.length;
                            if (numberTresholds > 0) {
                                // tresholds = loc.tresholds;
                                for (var i = 0; i < numberTresholds; i++) {
                                    tresholds.push({
                                        value: loc.tresholds[i].value,
                                        color: loc.tresholds[i].color,
                                        label: {
                                            text: loc.tresholds[i].description
                                        },
                                        width: 2,
                                        zIndex: 5
                                    });
                                }
                            } */

                            chartOptions = {
                                chart: {
                                    borderWidth: 2,
                                    renderTo: $('.' + loc.stationCode).get(0),
                                    zoomType: 'x',
                                    //type: chartType,
                                    //height:300,
                                    width:600,
                                    //marginRight:50
                                },
                                title: {
                                    text: loc.stationName,
                                    x: -20 //center
                                },
                                xAxis: {
                                    type: 'datetime',
                                    dateTimeLabelFormats: { // don't display the dummy year
                                        month: '%e. %b',
                                        year: '%b'
                                    },
                                },
                                /* yAxis: {
                                    title: {
                                        text: loc.parameterName + ' (' + loc.unit + ')'
                                    },
                                    plotLines: tresholds
                                }, */
                                yAxis: yAxisConfig,
                                tooltip: {
                                    valueSuffix: ' ' + loc.unit
                                },
                                legend: {
                                    layout: 'vertical',
                                    align: 'center',
                                    verticalAlign: 'top',
                                    floating: false,
                                    borderWidth: 0,
                                    maxHeight: 200
                                },
                                series: seriesConfig,
                                /* series: [{
                                    name: loc.name,
                                    color: loc.color,
                                    data: chartData
                                }] */
                                plotOptions: {
                                    series: {
                                        events: {
                                            legendItemClick: function () {
                                                if (this.yAxis.visible) {
                                                    this.yAxis.update({
                                                        visible: false
                                                    });
                                                } else {
                                                    this.yAxis.update({
                                                        visible: true
                                                    });
                                                }
                                            }
                                        }
                                    }
                                }
                            };
                            console.log("Chart Options:");
                            console.log(chartOptions);
                            chart = new Highcharts.Chart(chartOptions);
                        });
                    });
                    google.maps.event.addListener(marker, 'click', (function (marker, content, infowindow) {
                        return function () {
                            infowindow.setContent(content);
                            infowindow.open(dataMapElement, marker);
                        };
                    })(marker, chartContainer, infowindow));
                });

                return elements;
            },
            removeMarker: function(loc) {
                console.log("Called API method 'removeMarker'");
                markers[loc.groupCode + ':' + loc.stationCode].setMap(null);

                return elements;
            }
        };
    }
})(jQuery);