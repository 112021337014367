/**
 * Created by chris on 20.03.17.
 */
;(function($) {
    $.fn.loopPlayer = function (options) {
        /*
        Plugin variables
         */
        var elements = this;
        var frames = [];
        var imgSrcs = [];

        /*
        Default settings
         */
        this.defaultOptions = {
            duration: 1000
        };
        var settings = $.extend({}, this.defaultOptions, options);

        /*
        Plugin functions
         */
        var registerFrame = function (frame) {
            frames.push(frame);
        };
        var removeFrame = function (frame) {
            frames.filter(function (object) {
                return object.imgSrc !== frame.imgSrc
            });
        };
        var lastFrame = function () {
            return frames[frames.length - 1];
        };
        var firstFrame = function () {
            return frames[0];
        };

        var playFrames = function (event) {
            frames.forEach(function (frame) {
                frame.play();
            });
        };
        var prevFrames = function (event) {
            frames.some(function (frame) {
                return frame.prev();
            });
        };
        var nextFrames = function (event) {
            frames.some(function (frame) {
                return frame.next();
            });
        };
        var ffwdFrames = function (event) {
            frames.forEach(function (frame) {
                frame.ffwd();
            });
        };
        var pauseFrames = function (event) {
            frames.forEach(function (frame) {
                frame.pause();
            });
        };
        var close = function () {
            frames.forEach(function (frame) {
                frame.close();
            });
        };

        /*
        Plugin events registration
         */
        $(elements).siblings('.close-player').on('click', function (event) {
            event.preventDefault();
            event.stopPropagation();

            $(this).parents('.loop-player-overlay').fadeOut(400, function () {
                close();
            });
        });
        $(elements).find('.controls').on('click', '.play', function (event) {
            event.preventDefault();
            event.stopPropagation();

            playFrames();
        });
        $(elements).find('.controls').on('click', '.pause', function (event) {
            event.preventDefault();
            event.stopPropagation();

            pauseFrames();
        });
        $(elements).find('.controls').on('click', '.ffwd', function (event) {
            event.preventDefault();
            event.stopPropagation();

            ffwdFrames();
        });
        $(elements).find('.controls').on('click', '.prev_frame', function (event) {
            event.preventDefault();
            event.stopPropagation();

            prevFrames();
        });
        $(elements).find('.controls').on('click', '.next_frame', function (event) {
            event.preventDefault();
            event.stopPropagation();

            nextFrames();
        });

        /*
        Plugin code
         */
        $(this).parents('.loop-player-overlay').fadeIn();

        var imageList = $(elements).find('ul li');
        imageList.each(function (index) {
            var imageUrl = $(this).data('url');
            var frame = new Frame(index, imageUrl, settings.duration, $(this));
            if (index > 0) {
                lastFrame().nextFrame = frame;
                frame.prevFrame = lastFrame();
            }
            registerFrame(frame);

        });
        lastFrame().nextFrame = firstFrame();
        firstFrame().prevFrame = lastFrame();

        firstFrame().preload();
        firstFrame().currentState = 'pause'; // Initial state is 'pause'
        firstFrame().show(); // first frame should be visible, of course
        firstFrame().play();

        /*
        Plugin objects: in this case a Frame object
         */
        function Frame (index, imgSrc, duration, parentElement) {
            return {
                index: index,
                visible: false,
                imgSrc: imgSrc,
                parentElement: parentElement,
                baseDuration: duration,
                currentDuration: duration,

                currentState: 'play',
                startedAt: null,
                timer: null,

                nextFrame: null,
                prevFrame: null,

                play: function () {
                    this.currentDuration = this.baseDuration;
                    if (this.isVisible()) {
                        clearTimeout(this.timer);
                        var that = this;
                        this.timer = setTimeout(function () {
                            that.next();
                            that.nextFrame.play();
                        }, this.currentDuration);
                    }

                    this.currentState = 'play';
                },
                ffwd: function () {
                    this.currentDuration = this.baseDuration / 2;
                    if (this.isVisible()) {
                        clearTimeout(this.timer);
                        var that = this;
                        this.timer = setTimeout(function () {
                            that.next();
                            that.nextFrame.ffwd();
                        }, this.currentDuration);
                    }

                    this.currentState = 'ffwd';
                },
                pause: function () {
                    clearTimeout(this.timer);
                    if (this.isVisible()) {// && this.currentState != 'pause') {
                        this.currentDuration = Date.now() - this.startedAt;
                    }

                    this.currentState = 'pause';
                },
                prev: function () {
                    if (this.isVisible()) {
                        clearTimeout(this.timer);
                        this.prevFrame.show();
                        this.hide();

                        return true;
                    }
                    return false;
                },
                next: function () {
                    if (this.isVisible()) {
                        clearTimeout(this.timer);
                        this.nextFrame.show();
                        this.hide();

                        return true;
                    }
                    return false;
                },
                close: function () {
                    clearTimeout(this.timer);
                    this.parentElement.hide();
                    this.startedAt = null;
                    this.currentState = 'play';
                },

                isVisible: function () {
                    return this.visible;
                },
                preload: function () {
                    //if (this.parentElement.find('img').length == 0) {
                    if (this.parentElement.data('imgloaded') === false) {
                        this.parentElement.data('imgloaded', true);
                        var img = new Image();

                        var that = this;
                        img.onload = function () {
                            $(img).appendTo(that.parentElement);
                        };
                        img.src = this.imgSrc;
                    }
                },
                show: function () {
                    this.visible = true;
                    this.parentElement.show();
                    this.nextFrame.preload();
                    this.prevFrame.preload();
                    this.startedAt = Date.now();
                },
                hide: function () {
                    this.visible = false;
                    this.parentElement.hide();
                    this.startedAt = null;
                }
            };
        }
    }
})(jQuery);